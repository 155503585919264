const initialState = {
  balance: {},
  loading: false,
  transactionLoading: false,
  transactions:{},
  kybToken:{},
  iavToken:{},
  paymentInfo:{},
  bankingInfo:false
};

export default function transactionReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_WALLET_BALANCE":
      return {
        ...state,
        balance: action.payload,
      };

    case "SET_BALANCE_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "RESET_BALANCE_LOADING":
      return {
        ...state,
        loading: false,
      };
      case "SET_WALLET_TRANSACTIONS":
        return {
          ...state,
          transactions: action.payload,
        };
    case "SET_TRANSACTIONS_LOADING":
      return {
        ...state,
        transactionLoading: true,
      };

    case "RESET_TRANSACTIONS_LOADING":
      return {
        ...state,
        transactionLoading: false,
      };
    case "SET_KYB_DATA":
      return {
        ...state,
        kybToken: action.payload,
      };
    case "SET_PAYMENTS_DATA":
      return {
        ...state,
        paymentInfo: action.payload,
      };
    case "RESET_PAYMENTS_DATA":
      return {
        ...state,
        paymentInfo: {},
      };
    case "SET_BANKING_INFO":
      return {
        ...state,
        bankingInfo: action.payload,
      };
      case "SET_IAV_DATA":
        return {
          ...state,
          iavToken: action.payload,
        };
    
    default:
      return state;
  }
}
