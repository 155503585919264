import { Button, Card, Table, Typography, Modal, Descriptions } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Payments.css";
import { useAuth0 } from "@auth0/auth0-react";
import { withdrawFunds, savePaymentsInfo, getBankingInfo, getIavSdkToken, saveBankInfo } from "../../actions/Actions";
import { formatAmount, formatdate } from "../../util";
import Title from "antd/es/typography/Title";


function Payments() {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState(
    "Are you sure you want to withdraw funds?"
  );
  const payments = useSelector((state) => state.payments);
  const currentDealerhost = useSelector((state) => state.dealerHosts.current);
  console.log(payments);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setModalText(
      `Initiated transaction to withdraw $${payments.balance.balance} from wallet to the linked account`
    );
    setConfirmLoading(true);
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        console.log('TOKEN',token)
        if (currentDealerhost) {
          dispatch(
            withdrawFunds(currentDealerhost?.dealer_id, token, user.email)
          );
        }
      });
    }
  };

  useEffect(() => {
    if (!payments?.transactionLoading) {
      setOpen(false);
      setConfirmLoading(false);
    }
  }, [payments.transactionLoading]);
  
  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      if (payments.paymentInfo?.external_customer_id !== null || payments.paymentInfo?.external_customer_id !== undefined) {
      dispatch(
        getIavSdkToken(payments.bankingInfo?.bankingInfo?.external_customer_id, token)
      );
    }
      if (currentDealerhost) {
        dispatch(
          getBankingInfo(currentDealerhost?.dealer_id, token, user.email)
        );
      }
    });
  },[payments.paymentInfo]);

  const handleCancel = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "created",
      key: "created",
      render: (text) => formatdate(text),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => formatAmount(text),
    },
    {
      title: "Source Customer Name",
      dataIndex: ["source", "fundName"],
      key: "sourceCustomerName",
    },
    {
      title: "Destination Customer Name",
      dataIndex: ["destination", "fundName"],
      key: "destinationCustomerName",
    },
    {
      title: "Memo",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "Transfer Status",
      dataIndex: "transferStatus",
      key: "transferStatus",
    },
    {
      title: "Transfer Method",
      dataIndex: "transferMethod",
      key: "transferMethod",
    },
  ];
  return !payments.loading &&
    payments.paymentInfo?.external_payments_id === null &&
    payments.paymentInfo?.external_customer_id !== null && (payments.balance.message || Object.keys(payments.balance).length===0) ? (
    <>
      <h1>Waiting for Business to be confirmed</h1>
    </>
  ) : !payments.loading && Object.keys(payments.balance).length===0 ? (
    <>
      <Title>Open a wallet to get paid instantly</Title>
      <p>To be paid instantly register your business with FastPass Dealers</p>
      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
          const timer = setTimeout(() => {
            window.BaaS.renderEnrollBusiness({
              elementId: "demo",
              token: payments?.kybToken?.token,
              onComplete: (data) => {
                console.log(data.uid);
                getAccessTokenSilently().then((token) => {
                  if (currentDealerhost) {
                    dispatch(
                      savePaymentsInfo(
                        currentDealerhost?.dealer_id,
                        data.uid,
                        user.email,
                        token
                      )
                    );
                  }
                });
                setOpen(false);
              },
            });
          }, 100); // Adjust this delay as needed
          return () => clearTimeout(timer);
        }}
      >
        {" "}
        Open Wallet
      </Button>
      <Modal
        style={{
          top: 20,
        }}
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        <div id="demo"></div>
      </Modal>
    </>
  ) : !payments.loading && Object.keys(payments.balance).length!==0 ? (
    <>
      <div
        className="balance-block"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Card
          title=" Total Balances"
          style={{
            width: 300,
            marginTop: 16,
          }}
          loading={payments.loading}
        >
          Total Account Value:<strong> ${payments.balance.balance}</strong>
        </Card>
        <Modal
        style={{
          top: 20,
        }}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
        width={700}
      >
        <div id="bank"></div>
      </Modal>
        { payments?.bankingInfo?.bankingInfo?.external_payments_id!==null?(
        <Button type="primary" onClick={showModal}>
          Withdraw Balance
        </Button>):(
        <Button type="primary" onClick={() => {
          setOpenModal(true);
          const timer = setTimeout(() => {
            window.BaaS.renderAccountVerification({
              elementId: "bank",
              token: payments?.iavToken?.sessionToken,
              onComplete: (data) => {
                console.log(data);
                getAccessTokenSilently().then((token) => {
                  if (currentDealerhost) {
                    dispatch(
                      saveBankInfo(
                        currentDealerhost?.dealer_id,
                        data.uid,
                        user.email,
                        token
                      )
                    );
                  }
                });
                setOpenModal(false);
              },
            })
          },100)
          return() => clearTimeout(timer); 
          }}>
         Link External Account
        </Button>
        )
}
        <Modal
          title="Withdraw Funds"
          open={open}
          onOk={handleOk}
          okText="Confirm"
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
        >
          <p>{modalText}</p>
        </Modal>
      </div>

      <Typography.Title level={5} style={{ marginTop: 10 }}>
        Transactions
      </Typography.Title>
      <Table
        columns={columns}
        dataSource={payments?.transactions?.items}
        loading={payments?.transactionLoading}
        rowKey="uid"
      />
    </>
  ) : (
    <h1>Loading</h1>
  );
}

export default Payments;
