import React, { useEffect } from "react";
import { Space, Table, Button, Tag, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkAccessValid, getAllTransactions, getBalance, getLoanDetails, getTransactionById, getWalletTransactions } from "../../actions/Actions";
import { formatCreditCardNumber, formatdate } from "../../util";
import { useAuth0 } from "@auth0/auth0-react";



const Dashboard = () => {
  const { isAuthenticated, user, getIdTokenClaims , getAccessTokenSilently } = useAuth0();
  const { loginWithRedirect } = useAuth0();
  console.log("USER_INFO:",user)
  const currentDealerhost = useSelector((state) => state.dealerHosts.current);
  console.log("CURRENT_DEALERHOST",currentDealerhost)

  const columns = [
    {
      title: "Credit Card ID",
      dataIndex: "loan_id",
      key: "loan_id",
      render: (_, { loan_id }) => (
        <>
          {formatCreditCardNumber(loan_id)}
        </>
      ),
    },
    {
      title: "Make/Model/Year",
      dataIndex: "mmy",
      key: "mmy",
      render: (_, { make , model, year }) => (
        <>
          {make && model && year? (make?make:'') +'/' + (model?model:'') + '/' + (year?year:''): "NA"}
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_ts",
      key: "created_ts",
      render: (_, { created_ts }) => (
        <>
          {formatdate(created_ts)}
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          <Tag
            color={status === "Completed" ? "darkgreen" : status === "Lost" ? "volcano": "geekblue"}
            key={status}
          >
            {status.toUpperCase()}
          </Tag>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() =>handleButtonClick(record)}type="primary">Open</Button>
        </Space>
      ),
    },
  ];
  const navigate = useNavigate();
  const handleClick = () => {
    dispatch({
      type: "SET_TRANSACTION_ID",
      payload: null,
    });
    navigate("/transaction");
  };
  const handleButtonClick = (record) => {
    console.log('Clicked Row:', record);
    dispatch({
      type: "SET_TRANSACTION_ID",
      payload: record.id,
    });
    dispatch(getTransactionById(record.id));

    dispatch(checkAccessValid({loan_id:record.loan_id, dealer_id:currentDealerhost.dealer_id}))
    dispatch(
      getLoanDetails({
        memberId: record.member_id,
        cardId: record.loan_id,
        dealer_id:currentDealerhost.dealer_id
      })
    );
    navigate("/transaction");
  };
  const dispatch = useDispatch();
  const transactions = useSelector((state) => state.transaction.all);
  const transactionsobj = useSelector((state) => state.transaction);

  console.log("TRANSACTIONS:",transactions)
  console.log("TRANSACTIONSOBJ:",transactionsobj)

  useEffect(() => {
    dispatch({
      type: "RESET_TRANSACTION"
    });
    dispatch({
      type: "RESET_LOAN_DATA"
    });
    dispatch({
      type: "RESET_LOAN_ACCESS_REQUEST_SENT"
    });
    if(currentDealerhost){
      console.log("CDH",currentDealerhost)
    dispatch(getAllTransactions(currentDealerhost.dealer_id))
    }
  }, [currentDealerhost])

  useEffect(() => {
    if(isAuthenticated){
       getAccessTokenSilently().then((token)=>{
        console.log("DASHBOARDTOKEN", token)
        if(currentDealerhost && process.env.REACT_APP_SHOW_PAYMENTS==='1'){
          dispatch(getBalance(currentDealerhost.dealer_id, token))
          dispatch(getWalletTransactions(currentDealerhost.dealer_id, token))
        }
       })
      }
  }, [])
  

  useEffect(() => {
    async function handleLogin () {
      await loginWithRedirect({
        connection: 'email', // Specify the connection name for passwordless email
      scope: 'openid profile email'
        
      });
    };
    if (!isAuthenticated) {
      handleLogin();
    }
  }, [isAuthenticated,loginWithRedirect]);
  
  return (
    transactions.length>0?(
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Typography.Title level={5} style={{ marginTop: 10 }}>
          My Dashboard
        </Typography.Title>
        <Button type="primary" onClick={handleClick}>
          New Transaction
        </Button>
      </div>
      <Table columns={columns} dataSource={transactions} rowKey={(transactions)=> transactions.id} />
    </>
    )
    :
    (
      <div style={{
        display: "flex",
        flexDirection: "center",
        justifyContent: "center",
        alignItems: "center",
        margin: "10%"
      }}>
      <Button style={{
        fontSize: "xxx-large",
        height: "fit-content"

      }} type="primary" onClick={handleClick}>
      Start a New Transaction !
    </Button>
    </div>
    )
  );
};

export default Dashboard;
